import { Address } from "./Address";
import { Agent } from "./Agent";
import { BusinessHoursElement } from "./BusinessHoursElement";
import { assign } from "lodash-es";
import { BusinessCardType } from "~/enums/BusinessCardType";

export abstract class BranchDto {
    id!: number;
    name!: string;
    address!: Address;
    branchType!: BranchType;
    hours?: Array<BusinessHoursElement>;
    active?: boolean;
    agents?: Agent[];
    businessProfileUrl?: string;
}

export class Branch extends BranchDto {
    constructor(branch?: BranchDto) {
        super();
        assign(this, branch);
        this.agents = this.agents.map(agent => new Agent(agent));
    }

    //starzy agenci mogą nie mieć typu.
    get lendiExperts(): Agent[] {
        return this.agents.filter(agent =>
            agent.businessCardsTypes?.length ? agent.businessCardsTypes.includes(BusinessCardType.LENDI) : true);
    };

    get lendiCareAgents(): Agent[] {
        return this.agents.filter(agent => agent.businessCardsTypes?.includes(BusinessCardType.LENDI_CARE));
    };
}

export enum BranchType {
    FRANCHISE = 'franchise',
    OWN = 'own',
    CERTIFIED = 'certified'
}
